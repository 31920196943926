/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { getContract } from '../../services/contracts';
import { createAmendement } from '../../services/amendement';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import Layout from '../../components/template/Layout';
import Select from '../../components/atoms/Select/Select';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';

function AddAmendment() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();

  const initialValues = {
    title: '',
    object: '',
    contact: '',
    document: null,
    modif: '',
    prestataire: '',
    equipement: '',
    dateDebut: '',
    dureeinitial: '',
    dureepreavis: '',
    doccontact: null,
    reconduction: '',
    anneerenouvellement: '',
  };
  const validationSchema = Yup.object({
    title: Yup.string().required(t('global.required_field')),
    object: Yup.string().required(t('global.required_field')),
    document: Yup.mixed().nullable().typeError(t('global.wrong_type')).required('global.required_field')
      .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 10000000)
      .test('type', 'global.accepted_formats', (value) => value && (value.type === 'application/pdf'
        || value.type === 'application/x-pdf'
        || value.type === 'image/jpeg'
        || value.type === 'image/jpg'
        || value.type === 'image/png'
        || value.type === 'image/tiff'
        || value.type === 'image/bmp'
        || value.type === 'image/heic'
        || value.type === 'image/vnd.dwg'
      )),
    modif: Yup.string(),
    prestataire: Yup.string()
      .when('modif', {
        is: '1',
        then: Yup.string().required(t('global.required_field')),
      }),
    equipement: Yup.string()
      .when('modif', {
        is: '1',
        then: Yup.string().required(t('global.required_field')),
      }),
    dateDebut: Yup.string()
      .when('modif', {
        is: '1',
        then: Yup.string().required(t('global.required_field')),
      }),
    dureeinitial: Yup.string()
      .when('modif', {
        is: '1',
        then: Yup.string().required(t('global.required_field')),
      }),
    reconduction: Yup.string(),
    anneerenouvellement: Yup.string()
      .when('reconduction', {
        is: '1',
        then: Yup.string(),
      }),
    dureepreavis: Yup.string()
      .when('reconduction', {
        is: '1',
        then: Yup.string(),
      }),
  });

  const createAmendementQuery = useMutation(createAmendement, {
    onSuccess: () => {
      toast.success(t('contract.success'));
      navigate(-1);
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formdata = new FormData();
      formdata.append('title', values.title);
      formdata.append('purpose', values.object);
      formdata.append('documentFile', values.document);
      formdata.append('contractId', urlParams.id);
      createAmendementQuery.mutate(formdata);
      if (values.modif === '1') {
        formdata.append('contact', values.contact);
        formdata.append('doccontact', values.doccontact);
        formdata.append('prestataire', values.prestataire);
        formdata.append('equipement', values.equipement);
        formdata.append('dateDebut', values.dateDebut);
        formdata.append('dureeinitial', values.dureeinitial);
        formdata.append('reconduction', values.reconduction);
        if (values.reconduction === '1') {
          formdata.append('anneerenouvellement', values.anneerenouvellement);
          formdata.append('dureepreavis', values.dureepreavis);
        }
      }
    },
  });
  const dureepreavis = [
    {
      label: '1 mois',
      value: 1,
    },
    {
      label: '2 mois',
      value: 2,
    },
    {
      label: '3 mois',
      value: 3,
    },
    {
      label: '4 mois',
      value: 4,
    },
    {
      label: '5 mois',
      value: 5,
    },
    {
      label: '6 mois',
      value: 6,
    },
    {
      label: '7 mois',
      value: 7,
    },
    {
      label: '8 mois',
      value: 8,
    },
    {
      label: '9 mois',
      value: 9,
    },
    {
      label: '10 mois',
      value: 10,
    },
    {
      label: '11 mois',
      value: 11,
    },
    {
      label: '12 mois',
      value: 12,
    },
  ];
  const getContractQuery = useMutation(getContract, {
    onSuccess: (res) => {
      // retrouver le select qui correspond au dureepreavis
      formik.setFieldValue('dureepreavis', res.data.noticePeriodDuration);
      formik.setFieldValue('anneerenouvellement', res.data.tacitRenewalYears);
      formik.setFieldValue('dureeinitial', res.data.initialDuration);
      formik.setFieldValue('contact', res.data.societyName);
      formik.setFieldValue('prestataire', res.data.societyName);
      formik.setFieldValue('equipement', res.data.equipmentName);
      formik.setFieldValue('dateDebut', format(new Date(res.data.effectiveDate), 'yyyy-MM-dd'));
      formik.setFieldValue('documentFileName', res.data.documentFileName);
      if (res.data.framework === true) {
        formik.setFieldValue('reconduction', '1');
      } else {
        formik.setFieldValue('reconduction', '0');
      }
      formik.setFieldValue('valorisation', res.data.valorisation);
      formik.setFieldValue('dureepreavis', res.data.noticePeriodDuration);
    },
  });
  useEffect(() => {
    getContractQuery.mutate(urlParams.id);
  }, []);
  return (
    <Layout
      title={t('contract.add_amendement')}
      layout="form"
      queryError={createAmendementQuery?.error
        || getContractQuery?.error}
    >
      <div>
        <header>
          <div className="row mb-20">
            <button
              type="button"
              className="link"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('contract.back_listAmendement')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">
              {t('contract.add_amendement')}
              <br />
              <span className="title-info">
                {` ${getContractQuery?.data?.data?.societyName} - ${getContractQuery?.data?.data?.equipmentName}`}
              </span>
            </h1>
          </div>
        </header>
      </div>
      <form onSubmit={formik.handleSubmit} className="form shadow-sm">
        <div className="form_group">
          <Input
            name="title"
            type="text"
            label={t('contract.titleAdd')}
            value={formik.values.title}
            onChange={formik.handleChange}
            required
          />
          {formik.errors.title && formik.touched.title ? (
            <div className="error">
              {t(formik.errors.title)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            name="object"
            type="text"
            label={t('contract.object')}
            value={formik.values.object}
            onChange={formik.handleChange}
            required
          />
          {formik.errors.object && formik.touched.object ? (
            <div className="error">
              {t(formik.errors.object)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            name="contrat"
            type="text"
            label={t('contract.contract')}
            value={formik.values.contact}
            disabled
          />
        </div>
        <div className="form_group">
          <div className="label">{t('add_training.document_file')}</div>
          <UploadFile
            id="documentFile"
            name="document"
            label={t('contract.report')}
            fileName={formik.values.document ? formik.values.document.name : formik.values.document}
            onChange={(event) => formik.setFieldValue('document', event.currentTarget.files[0])}
            onBlur={formik.handleBlur}
            setFieldValue={formik.setFieldValue}
          />
          {formik.errors.document && formik.touched.document ? (
            <div className="error">
              {t(formik.errors.document)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Checkbox
            id="modif"
            name="modif"
            label={t('contract.titleContract')}
            value={formik.values.modif === '1' ? '1' : '0'}
            checked={formik.values.modif === '1'}
            onChange={(e) => formik.setFieldValue('modif', e.target.checked ? '1' : '0')}
            onBlur={formik.handleBlur}
          />
          {formik.errors.modif && formik.touched.modif ? (
            <div className="error">
              {t(formik.errors.modif)}
            </div>
          ) : null }
        </div>
        <br />
      </form>
      {formik.values.modif === '1' && (
        <>
          <h1 className="title">{t('contract.titleContract')}</h1>
          <form onSubmit={formik.handleSubmit} className="form shadow-sm">
            <div className="form_group">
              <Input
                name="prestataire"
                type="text"
                label={t('contract.prestataire')}
                value={formik.values.prestataire}
                disabled
              />
              {formik.errors.prestataire && formik.touched.prestataire ? (
                <div className="error">
                  {t(formik.errors.prestataire)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                name="equipement"
                type="text"
                label={t('contract.equipement')}
                value={formik.values.equipement}
                disabled
              />
            </div>
            <div className="form_group">
              <Input
                name="dateDebut"
                type="date"
                label={t('contract.dateDebut')}
                value={formik.values.dateDebut}
                onChange={formik.handleChange}
                required
              />
              {formik.errors.dateDebut && formik.touched.dateDebut ? (
                <div className="error">
                  {t(formik.errors.dateDebut)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                name="dureeinitial"
                type="number"
                min="0"
                label={t('contract.dureeinitial')}
                value={formik.values.dureeinitial}
                onChange={formik.handleChange}
                required
              />
              {formik.errors.dureeinitial && formik.touched.dureeinitial ? (
                <div className="error">
                  {t(formik.errors.dureeinitial)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                name="valorisation"
                type="number"
                min="0"
                label={t('contract.valorisation')}
                value={formik.values.valorisation}
                onChange={formik.handleChange}
              />
              {formik.errors.valorisation && formik.touched.valorisation ? (
                <div className="error">
                  {t(formik.errors.valorisation)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              {' '}
            </div>
            <div className="form_group">
              <Checkbox
                id="reconduction"
                name="reconduction"
                label={t('contract.reconduction')}
                value={formik.values.reconduction === '1' ? '1' : '0'}
                checked={formik.values.reconduction === '1'}
                onChange={(e) => formik.setFieldValue('reconduction', e.target.checked ? '1' : '0')}
                onBlur={formik.handleBlur}
              />
              {formik.errors.reconduction && formik.touched.reconduction ? (
                <div className="error">
                  {t(formik.errors.reconduction)}
                </div>
              ) : null }
            </div>
            <br />
            {formik.values.reconduction === '1' && (
              <>
                <div className="form_group">
                  <Input
                    name="anneerenouvellement"
                    type="number"
                    label={t('contract.anneerenouvellement')}
                    value={formik.values.anneerenouvellement}
                    onChange={formik.handleChange}
                    required={formik.values.reconduction === '1'}
                  />
                  {formik.errors.anneerenouvellement
                    && formik.touched.anneerenouvellement
                    && formik.touched.reconduction === 1 ? (
                      <div className="error">
                        {t(formik.errors.anneerenouvellement)}
                      </div>
                    ) : null }
                </div>
                <div className="form_group">
                  <Select
                    name="dureepreavis"
                    type="select"
                    label={t('contract.dureepreavis')}
                    value={
                      dureepreavis.find(
                        (item) => item.value === formik.values.dureepreavis,
                      )
                     }
                    onChange={(value) => formik.setFieldValue('dureepreavis', value.value)}
                    options={dureepreavis}
                    valueInput={formik.values.dureepreavis}
                    required={formik.values.reconduction === '1'}
                  />
                  {formik.errors.dureepreavis && formik.touched.dureepreavis && formik.touched.reconduction === 1 ? (
                    <div className="error">
                      {t(formik.errors.dureepreavis)}
                    </div>
                  ) : null }
                </div>
              </>
            )}
            <div className="form_group">
              <div className="label">{t('add_training.document_file')}</div>
              <UploadFile
                id="doccontact"
                name="doccontact"
                label={t('contract.doccontact')}
                fileName={formik.values.doccontact ? formik.values.doccontact.name : formik.values.documentFileName}
                onChange={(event) => formik.setFieldValue('doccontact', event.currentTarget.files[0])}
                onBlur={formik.handleBlur}
                setFieldValue={formik.setFieldValue}
              />
              {formik.errors.doccontact && formik.touched.doccontact ? (
                <div className="error">
                  {t(formik.errors.doccontact)}
                </div>
              ) : null }
            </div>
          </form>
        </>
      )}
      <form onSubmit={formik.handleSubmit} className="form shadow-sm">
        <section className="form_footer">
          <div className="form_infos">
            <small>{t('add_structure.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t('contract.save')}
          />
        </section>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('contract.back_listAmendement')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default AddAmendment;
