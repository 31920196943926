import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { sortBy } from 'lodash';
import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { getSocietiesList } from '../../services/societies';
import {
  createContract,
  getContract,
  getContractDocument,
  updateContract,
} from '../../services/contracts';
import { getEquipmentsList } from '../../services/equipments';
import useAppContext from '../../store/useAppContext';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import Layout from '../../components/template/Layout';
import Select from '../../components/atoms/Select/Select';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';

function Contract() {
  const { t } = useTranslation();
  const context = useAppContext();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [society, setSociety] = React.useState('');
  const [equipments, setEquipment] = React.useState([]);

  const prestataire = useQuery(['societiesList'], () => getSocietiesList({
    structureId: context[0]?.choiceEstablishment?.id,
    bookletId: context[0]?.choiceBooklet,
    establishmentId: context[0]?.choiceEstablishment?.id,
  }));

  const equipement = useQuery(['equipmentsList'], () => getEquipmentsList({
    structureId: context[0]?.choiceEstablishment?.id,
    bookletId: context[0]?.choiceBooklet,
    establishmentId: context[0]?.choiceEstablishment?.id,
  }));

  const goBackUrl = (message) => {
    navigate(-1);
    toast.success(message);
  };

  const createContractMutation = useMutation(createContract, {
    onSuccess: () => {
      goBackUrl(t('contract.add_success'));
    },
  });

  const mapForSelect = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['name']).map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }))
    : []);
  const initialValues = {
    prestataire: '',
    equipement: '',
    dateDebut: '',
    dureeinitial: '',
    dureepreavis: 0,
    valorisation: 0,
    document: null,
    reconduction: 0,
    anneerenouvellement: 0,
  };
  const validationSchema = Yup.object({
    prestataire: Yup.string().required(t('global.required_field')),
    equipement: Yup.string().required(t('global.required_field')),
    dateDebut: Yup.string().required(t('global.required_field')),
    dureeinitial: Yup.string().required(t('global.required_field')),
    document: Yup.mixed().nullable()
      .when('urlParams', {
        is: true,
        then: Yup.mixed().typeError(t('global.wrong_type')).required('global.required_field')
          .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 10000000)
          .test('type', 'global.accepted_formats', (value) => value && (value.type === 'application/pdf'
            || value.type === 'application/x-pdf'
            || value.type === 'image/jpeg'
            || value.type === 'image/jpg'
            || value.type === 'image/png'
            || value.type === 'image/tiff'
            || value.type === 'image/bmp'
            || value.type === 'image/heic'
            || value.type === 'image/vnd.dwg'
          )),
      }),
    reconduction: Yup.string(),
    anneerenouvellement: Yup.string()
      .when('reconduction', {
        is: 1,
        then: Yup.number().required(t('global.required_field')),
      }),
    dureepreavis: Yup.string()
      .when('reconduction', {
        is: 1,
        then: Yup.number().required(t('global.required_field')),
      }),
    valorisation: Yup.number().nullable(),
  });

  const putContact = useMutation(updateContract, {
    onSuccess: () => {
      goBackUrl(t('contract.succes_save_modif'));
    },
    onError: () => {
      toast.error(t('contract.erreur_save_modif'));
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('structureIds[0]', context[0]?.choiceEstablishment?.id);
      formData.append('equipmentId', values.equipement);
      formData.append('societyId', values.prestataire);
      formData.append('framework', 0);
      formData.append('effectiveDate', values.dateDebut);
      formData.append('initialDuration', values.dureeinitial);
      formData.append('noticePeriodDuration', values.dureepreavis);
      formData.append('tacitRenewalYears', values.anneerenouvellement);
      formData.append('valorisation', values.valorisation);
      formData.append('bookletId', context[0]?.choiceBooklet);
      if (urlParams.id) {
        const value = {
          societyId: `${values.prestataire}`,
          equipmentId: `${values.equipement}`,
          effectiveDate: `${format(new Date(values.dateDebut), 'yyyy-MM-dd')}`,
          initialDuration: `${values.dureeinitial}`,
          valorisation: `${values.valorisation}`,
          noticePeriodDuration: `${values.dureepreavis}`,
          tacitRenewalYears: `${values.anneerenouvellement}`,
          bookletId: `${context[0]?.choiceBooklet}`,
        };
        putContact.mutate({ id: urlParams.id, data: value });
      } else {
        formData.append('documentFile', values.document);
        createContractMutation.mutate(formData);
      }
    },
  });
  const getContractQuery = useMutation(getContract, {
    onSuccess: (res) => {
      setSociety(res.data.societyName);
      setEquipment(res.data.equipmentName);
      formik.setFieldValue('equipement', res.data.equipmentId);
      formik.setFieldValue('prestataire', res.data.societyId);
      formik.setFieldValue('dateDebut', format(new Date(res.data.effectiveDate), 'yyyy-MM-dd'));
      formik.setFieldValue('dureeinitial', res.data.initialDuration);
      formik.setFieldValue('valorisation', res.data.valorisation);
      if (res.data.tacitRenewalYears || res.data.noticePeriodDuration) {
        formik.setFieldValue('reconduction', 1);
      }
      formik.setFieldValue('dureepreavis', res.data.noticePeriodDuration);
      formik.setFieldValue('anneerenouvellement', res.data.tacitRenewalYears);
      formik.setFieldValue('document', res.data.documentFileName);
    },
  });
  // dowload document contract
  const dowloaddoc = (data) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'document.pdf');
    document.body.appendChild(link);
    link.click();
  };

  // call api to get document contract
  const getContractDocuments = useMutation(getContractDocument, {
    onSuccess: (data) => {
      dowloaddoc(data.data);
    },
    onError: () => {
      toast.error(t('contract.erreur_get_document'));
    },
  });
  useEffect(() => {
    getContractQuery.mutate(urlParams.id);
  }, []);
  const dureepreavis = [
    {
      label: '1 mois',
      value: '1',
    },
    {
      label: '2 mois',
      value: '2',
    },
    {
      label: '3 mois',
      value: '3',
    },
    {
      label: '4 mois',
      value: '4',
    },
    {
      label: '5 mois',
      value: '5',
    },
    {
      label: '6 mois',
      value: '6',
    },
    {
      label: '7 mois',
      value: '7',
    },
    {
      label: '8 mois',
      value: '8',
    },
    {
      label: '9 mois',
      value: '9',
    },
    {
      label: '10 mois',
      value: '10',
    },
    {
      label: '11 mois',
      value: '11',
    },
    {
      label: '12 mois',
      value: '12',
    },
  ];
  return (
    <Layout
      title="Contrat"
      layout="form"
      queryError={
        prestataire?.error
        || equipement?.error
        || createContractMutation?.error
      }
    >
      <div>
        <header>
          <div className="row mb-20">
            <button
              type="button"
              className="link"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('contract.back_add')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('contract.title_page')}</h1>
          </div>
        </header>
      </div>
      <form onSubmit={formik.handleSubmit} className="form shadow-sm">
        <div className="form_group">
          <Select
            name="prestataire"
            type="text"
            label={t('contract.prestataire')}
            value={
              mapForSelect(prestataire).find(
                (item) => item.value === formik.values.prestataire,
              )
            }
            onChange={(value) => formik.setFieldValue('prestataire', value.value)}
            options={mapForSelect(prestataire?.data?.data?.societies)}
            error={formik.errors.prestataire}
            required
            valueInput={formik.values.prestataire}
            loading={prestataire.isLoading}
            placeholder={society}
          />
          {formik.errors.prestataire && formik.touched.prestataire ? (
            <div className="error">
              {t(formik.errors.prestataire)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Select
            name="equipement"
            type="text"
            label={t('contract.equipement')}
            value={
              mapForSelect(equipement).find(
                (item) => item.value === formik.values.equipement,
              )
            }
            onChange={(value) => formik.setFieldValue('equipement', value.value)}
            options={mapForSelect(equipement?.data?.data?.equipments)}
            required
            valueInput={formik.values.equipement}
            loading={equipement.isLoading}
            placeholder={equipments}
          />
          {formik.errors.equipement && formik.touched.equipement ? (
            <div className="error">
              {t(formik.errors.equipement)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            name="dateDebut"
            type="date"
            label={t('contract.dateDebut')}
            value={formik.values.dateDebut}
            onChange={formik.handleChange}
            required
          />
          {formik.errors.dateDebut && formik.touched.dateDebut ? (
            <div className="error">
              {t(formik.errors.dateDebut)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            name="dureeinitial"
            type="number"
            min="0"
            label={t('contract.dureeinitial')}
            value={formik.values.dureeinitial}
            onChange={formik.handleChange}
            required
          />
          {formik.errors.dureeinitial && formik.touched.dureeinitial ? (
            <div className="error">
              {t(formik.errors.dureeinitial)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            name="valorisation"
            type="number"
            label={t('contract.valorisation')}
            value={formik.values.valorisation}
            onChange={formik.handleChange}
          />
          {formik.errors.valorisation && formik.touched.valorisation ? (
            <div className="error">
              {t(formik.errors.valorisation)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          {' '}
        </div>
        <div className="form_group">
          <Checkbox
            id="reconduction"
            name="reconduction"
            label={t('contract.reconduction')}
            value={formik.values.reconduction === 1 ? 1 : 0}
            checked={formik.values.reconduction === 1}
            onChange={(e) => formik.setFieldValue('reconduction', e.target.checked ? 1 : 0)}
            onBlur={formik.handleBlur}
          />
          {formik.errors.reconduction && formik.touched.reconduction ? (
            <div className="error">
              {t(formik.errors.reconduction)}
            </div>
          ) : null }
        </div>
        <br />
        {formik.values.reconduction === 1 && (
          <>
            <div className="form_group">
              <Input
                name="anneerenouvellement"
                type="number"
                min="0"
                label={t('contract.anneerenouvellement')}
                value={formik.values.anneerenouvellement}
                onChange={formik.handleChange}
                required
              />
              {formik.errors.anneerenouvellement
                && formik.touched.anneerenouvellement ? (
                  <div className="error">
                    {t(formik.errors.anneerenouvellement)}
                  </div>
                ) : null }
            </div>
            <div className="form_group">
              <Select
                name="dureepreavis"
                type="select"
                label={t('contract.dureepreavis')}
                value={
                  dureepreavis.find(
                    (item) => item.value === formik.values.dureepreavis,
                  )
                 }
                onChange={(value) => formik.setFieldValue('dureepreavis', value.value)}
                options={dureepreavis}
                valueInput={formik.values.dureepreavis}
                placeholder={urlParams.id && formik.values.dureepreavis}
                required
              />
              {formik.errors.dureepreavis && formik.touched.dureepreavis && formik.touched.reconduction === 1 ? (
                <div className="error">
                  {t(formik.errors.dureepreavis)}
                </div>
              ) : null }
            </div>
          </>
        )}
        { !urlParams.id ? (
          <div className="form_group">
            <div className="label">{t('add_training.document_file')}</div>
            <UploadFile
              id="documentFile"
              name="document"
              label={t('contract.report')}
              fileName={formik.values.document ? formik.values.document.name : formik.values.document}
              onChange={(event) => formik.setFieldValue('document', event.currentTarget.files[0])}
              onBlur={formik.handleBlur}
              setFieldValue={formik.setFieldValue}
            />
            {formik.errors.document && formik.touched.document ? (
              <div className="error">
                {t(formik.errors.document)}
              </div>
            ) : null }
          </div>
        ) : (
          <div className="form_group">
            <Button
              label={(
                <FontAwesomeIcon icon={faFileArrowDown} transform="grow-15" />
              )}
              type="button"
              className="action edit"
              title={t('societies.edit')}
              onClick={() => getContractDocuments.mutate(urlParams.id)}
            />
          </div>
        )}
        <section className="form_footer">
          <div className="form_infos">
            <small>{t('add_structure.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t('contract.add_contract')}
          />
        </section>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('contract.back_add')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default Contract;
