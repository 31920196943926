import React from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import {
  faChevronLeft,
  faCamera,
} from '@fortawesome/free-solid-svg-icons';
import {
  useFormik,
} from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAppContext from '../../store/useAppContext';
import { getObservation, postLift } from '../../services/registre';
import Layout from '../../components/template/Layout';
import Button from '../../components/atoms/Button/Button';
import cn from '../../utils/cn';
import styles from './Picture.module.css';
/* eslint-disable max-len */
/* eslint no-unsafe-optional-chaining: "error" */
function InternalLifting() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const UrlParams = useParams();
  const [source, setSource] = React.useState('');
  const [info, setInfo] = React.useState([]);
  const [context] = useAppContext();

  const getObservationQuery = useQuery(['getObservation'], () => getObservation({
    id: UrlParams.id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setInfo(data?.data);
    },
  });
  const postLiftMutation = useMutation(postLift, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('raisedObs.successave'));
    },
    onError: () => {
      toast.error(t('raisedObs.echecsave'));
    },
  });

  const validationSchema = Yup.object({
    photo: Yup.mixed().nullable().typeError(t('global.wrong_type'))
      .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 20000000)
      .test('type', 'global.accepted_formats', (value) => value && (value.type === 'image/jpeg'
        || value.type === 'image/jpg'
        || value.type === 'image/png'
        || value.type === 'image/tiff'
        || value.type === 'image/bmp'
        || value.type === 'image/heic')),
  });
  const initialValues = {
    photo: null,
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('documentFile', values.photo);
      formData.append('observationIds[0]', UrlParams?.id);
      formData.append('reportId', info?.report?.id);
      postLiftMutation.mutate(formData);
    },
  });
  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        setSource(newUrl);
        formik.setFieldValue('photo', target.files[0]);
      }
    }
  };
  return (
    <Layout
      title="InternalLifting"
      queryError={
        getObservationQuery?.error
      }
    >
      <header className="header">
        <div className={cn(['row', 'row'])}>
          <h1 className="title">{t('Lifted.title')}</h1>
        </div>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
        <span className="title-info">
          {info?.equipement}
          -
          {info?.numeroReport}
        </span>
      </header>
      <div className="title">
        <div>
          <div>
            <form
              onSubmit={formik.handleSubmit}
              className="form shadow"
            >
              <div className="form_group--fullwidth">
                <label htmlFor="icon-button-file" className={styles.photo}>
                  <FontAwesomeIcon icon={faCamera} size="6x" />
                  <input
                    accept="image/*"
                    className={styles.input}
                    id="icon-button-file"
                    type="file"
                    name="photo"
                    style={{ visibility: 'hidden' }}
                    capture="environment"
                    onChange={(e) => handleCapture(e.target)}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.photo && formik.touched.photo ? (
                    <div className="error">
                      {t(formik.errors.photo)}
                    </div>
                  ) : null }
                </label>
                <br />
                {source && (
                <img src={source} alt="snap" className={styles.img} />
                )}
              </div>
              <br />
              <div className="form_footer">
                <div className="form_infos">
                  <small>{t('addInterventions.mandatory_fields')}</small>
                </div>
                <Button
                  type="submit"
                  className="form_submit"
                  label={t('Lifted.save')}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer>
        <div className="row mt-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('raisedObs.return')}</span>
          </button>
        </div>
      </footer>
    </Layout>
  );
}

export default InternalLifting;
