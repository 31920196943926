/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { getContract, updateContract } from '../../services/contracts';
import { editAmendement, getAmendement } from '../../services/amendement';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import Layout from '../../components/template/Layout';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';
import Radio from '../../components/atoms/Radio/Radio';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Select from '../../components/atoms/Select/Select';
import Permissions from '../../components/molecules/Permissions/Permissions';

function EditAmendment() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();

  const initialValues = {
    title: '',
    purpose: '',
    contact: '',
    document: null,
    contractModif: 2,
    noticePeriodDuration: '',
    prestataire: '',
    equipement: '',
    effectiveDate: '',
    initialDuration: '',
    documentContract: null,
    reconduction: '',
    valorisation: '',
    tacitRenewalYears: '',
  };
  // validation formulaire
  const validationSchema = Yup.object({
    title: Yup.string().required(t('global.required_field')),
    purpose: Yup.string().required(t('global.required_field')),
    document: Yup.mixed().nullable().typeError(t('global.wrong_type'))
      .test('fileSize', 'global.file_too_large', (value) => value === null || value.size <= 10000000)
      .test('type', 'global.accepted_formats', (value) => value === null || (value.type === 'application/pdf'
        || value.type === 'application/x-pdf'
        || value.type === 'image/jpeg'
        || value.type === 'image/jpg'
        || value.type === 'image/png'
        || value.type === 'image/tiff'
        || value.type === 'image/bmp'
        || value.type === 'image/heic'
        || value.type === 'image/vnd.dwg'
      )),
    contractModif: Yup.number(),
    documentContract: Yup.mixed().nullable().typeError(t('global.wrong_type'))
      .when('contractModif', {
        is: 1,
        then: Yup.mixed().nullable().typeError(t('global.wrong_type'))
          .test('fileSize', 'global.file_too_large', (value) => value === null || value.size <= 10000000)
          .test('type', 'global.accepted_formats', (value) => value === null || (value.type === 'application/pdf'
            || value.type === 'application/x-pdf'
            || value.type === 'image/jpeg'
            || value.type === 'image/jpg'
            || value.type === 'image/png'
            || value.type === 'image/tiff'
            || value.type === 'image/bmp'
            || value.type === 'image/heic'
            || value.type === 'image/vnd.dwg'
          )),
      }),
    prestataire: Yup.string()
      .when('contractModif', {
        is: 1,
        then: Yup.string().required(t('global.required_field')),
      }),
    equipement: Yup.string()
      .when('contractModif', {
        is: 1,
        then: Yup.string().required(t('global.required_field')),
      }),
    effectiveDate: Yup.string()
      .when('contractModif', {
        is: 1,
        then: Yup.string().required(t('global.required_field')),
      }),
    initialDuration: Yup.string()
      .when('contractModif', {
        is: 1,
        then: Yup.string().required(t('global.required_field')),
      }),
    reconduction: Yup.boolean(),
    tacitRenewalYears: Yup.string()
      .when(['reconduction', 'contractModif'], {
        is: '1' || 1,
        then: Yup.string().required(t('global.required_field')),
      }),
    noticePeriodDuration: Yup.string()
      .when(['reconduction', 'contractModif'], {
        is: '1' || 1,
        then: Yup.string().required(t('global.required_field')),
      }),
    valorisation: Yup.number(),
  });

  const createAmendementQuery = useMutation(editAmendement, {
    onSuccess: () => {
      toast.success(t('contract.edit_success'));
      navigate(-1);
    },
  });

  const ModifyContractQuery = useMutation(updateContract, {
    onSuccess: () => {
      toast.success(t('contract.success'));
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formdata = new FormData();
      formdata.append('title', values.title);
      formdata.append('purpose', values.purpose);
      formdata.append('document', values.document);
      formdata.append('documentFile', values.documentContract);
      formdata.append('contractId', urlParams.id);
      formdata.append('equipmentId', values.equipement);
      formdata.append('effectiveDate', format(new Date(values.effectiveDate), 'yyyy-MM-dd'));
      formdata.append('initialDuration', values.initialDuration);
      formdata.append('noticePeriodDuration', values.noticePeriodDuration);
      formdata.append('valorisation', values.valorisation);
      formdata.append('tacitRenewalYears', values.tacitRenewalYears);
      const value = {
        societyId: `${values.societyId}`,
        equipmentId: `${values.equipmentId}`,
        effectiveDate: `${format(new Date(values.effectiveDate), 'yyyy-MM-dd')}`,
        initialDuration: `${values.initialDuration}`,
        valorisation: `${values.valorisation}`,
        noticePeriodDuration: `${values.noticePeriodDuration}`,
        tacitRenewalYears: `${values.tacitRenewalYears}`,
      };
      const config = {
        id: urlParams.contractid,
        data: value,
      };
      const Amendements = {
        contractId: urlParams.contractid,
        title: `${values.title}`,
        purpose: `${values.purpose}`,
      };
      const configAmendement = {
        ...Amendements,
        id: urlParams.amandementid,
      };
      if (values.contractModif === 1) {
        ModifyContractQuery.mutate(config);
      }
      createAmendementQuery.mutate(configAmendement);
    },
  });

  const noticePeriodDuration = [
    {
      label: '1 mois',
      value: '1',
    },
    {
      label: '2 mois',
      value: '2',
    },
    {
      label: '3 mois',
      value: '3',
    },
    {
      label: '4 mois',
      value: '4',
    },
    {
      label: '5 mois',
      value: '5',
    },
    {
      label: '6 mois',
      value: '6',
    },
    {
      label: '7 mois',
      value: '7',
    },
    {
      label: '8 mois',
      value: '8',
    },
    {
      label: '9 mois',
      value: '9',
    },
    {
      label: '10 mois',
      value: '10',
    },
    {
      label: '11 mois',
      value: '11',
    },
    {
      label: '12 mois',
      value: '12',
    },
  ];
  const getContractQuery = useMutation(getContract, {
    onSuccess: (data) => {
      formik.setFieldValue('contact', data.data.societyName);
      formik.setFieldValue('equipement', data.data.equipmentName);
      formik.setFieldValue('prestataire', data.data.societyName);
      formik.setFieldValue('effectiveDate', data.data.effectiveDate);
      formik.setFieldValue('initialDuration', data.data.initialDuration);
      formik.setFieldValue('societyId', data.data.societyId);
      formik.setFieldValue('equipmentId', data.data.equipmentId);
      if (data.data.tacitRenewalYears) {
        formik.setFieldValue('reconduction', '1');
      }
      formik.setFieldValue('valorisation', data.data.valorisation);
      formik.setFieldValue('noticePeriodDuration', data.data.noticePeriodDuration);
      formik.setFieldValue('tacitRenewalYears', data.data.tacitRenewalYears);
    },
  });
  const getAmendementQuery = useMutation(getAmendement, {
    onSuccess: (data) => {
      formik.setFieldValue('contactId', data.data.contractId);
      formik.setFieldValue('title', data.data.title);
      formik.setFieldValue('purpose', data.data.purpose);
    },
  });

  useEffect(() => {
    getContractQuery.mutate(urlParams.contractid);
    getAmendementQuery.mutate(urlParams.amandementid);
  }, []);
  return (
    <Layout
      title={t('contract.edit_amentdement')}
      layout="form"
      queryError={createAmendementQuery?.isError
        || ModifyContractQuery?.isError
        || getContractQuery?.isError
        || getAmendementQuery?.isError}
    >
      <div>
        <header>
          <div className="row mb-20">
            <button
              type="button"
              className="link"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('contract.back_listAmendement')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">{t('contract.edit_amentdement')}</h1>
          </div>
        </header>
      </div>
      <form onSubmit={formik.handleSubmit} className="form shadow-sm">
        <div className="form_group">
          <Input
            name="title"
            type="text"
            label={t('contract.titleAdd')}
            placeholder={getAmendementQuery?.data?.data?.title}
            value={formik.values.title}
            onChange={formik.handleChange}
            required
          />
          {formik.errors.title && formik.touched.title ? (
            <div className="error">
              {t(formik.errors.title)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            name="purpose"
            type="text"
            label={t('contract.object')}
            value={formik.values.purpose}
            onChange={formik.handleChange}
            required
          />
          {formik.errors.purpose && formik.touched.purpose ? (
            <div className="error">
              {t(formik.errors.purpose)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            name="contrat"
            type="text"
            placeholder={getContractQuery?.data?.data?.societyName}
            label={t('contract.contract')}
            value={formik.values.contact}
            disabled
          />
        </div>
        <div className="form_group">
          {' '}
        </div>
        <div className="form_group">
          <div className="label">{t('add_training.document_file')}</div>
          <div className="from_group--inline">
            <UploadFile
              id="document"
              name="document"
              label={t('contract.report')}
              placeholder={getAmendementQuery?.data?.data?.documentFileName}
              fileName={formik?.values?.document ? formik?.values?.document?.name : getAmendementQuery?.data?.data?.documentFileName}
              onChange={(event) => formik.setFieldValue('document', event.currentTarget.files[0])}
              setFieldValue={formik.setFieldValue}
            />
          </div>
          {formik.errors.document && formik.touched.document ? (
            <div className="error">
              {t(formik.errors.document)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          {' '}
        </div>
        <div className="form_group">
          <div className="label">{t('contract.modif')}</div>
          <div>
            <Radio
              id={t('contractModif')}
              name="contractModif"
              label={t('contract.contractModifyes')}
              onChange={(event) => formik.setFieldValue('contractModif', parseInt(event.target.value, 10))}
              onBlur={formik.handleBlur}
              checked={formik.values.contractModif === 1}
              value={1}
            />
          </div>
          <div>
            <Radio
              id={t('contractModif')}
              name="contractModif"
              label={t('contract.contractModifNo')}
              onChange={(event) => formik.setFieldValue('contractModif', parseInt(event.target.value, 10))}
              onBlur={formik.handleBlur}
              checked={formik.values.contractModif === 2}
              value={2}
            />
          </div>
        </div>
        {formik.errors.contractModif && formik.touched.contractModif ? (
          <div className="error">
            {t(formik.errors.contractModif)}
          </div>
        ) : null }
        <div className="form_group">
          {' '}
        </div>
        {formik.values.contractModif === 1 ? (
          <div className=" form form_group--fullwidth">
            <div className="form_group">
              <Input
                name="prestataire"
                type="text"
                label={t('contract.prestataire')}
                placeholder={getContractQuery?.data?.data?.societyName}
                value={formik.values.prestataire}
                onChange={formik.handleChange}
                disabled
              />
              {formik.errors.title && formik.touched.title ? (
                <div className="error">
                  {t(formik.errors.title)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                name="equipement"
                type="text"
                label={t('contract.equipement')}
                placeholder={getContractQuery?.data?.data?.equipmentName}
                value={formik.values.equipement}
                disabled
              />
              {formik.errors.equipement && formik.touched.equipement ? (
                <div className="error">
                  {t(formik.errors.equipement)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                name="effectiveDate"
                type="date"
                label={t('contract.dateDebut')}
                value={formik.values.effectiveDate ? format(new Date(formik.values.effectiveDate), 'yyyy-MM-dd') : ''}
                onChange={formik.handleChange}
                required={formik.values.contractModif === 1}
              />
              {formik.errors.effectiveDate && formik.touched.effectiveDate ? (
                <div className="error">
                  {t(formik.errors.effectiveDate)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                name="initialDuration"
                type="number"
                min="0"
                label={t('contract.dureeinitial')}
                value={formik.values.initialDuration}
                onChange={formik.handleChange}
                required={formik.values.contractModif === 1}
              />
              {formik.errors.initialDuration && formik.touched.initialDuration ? (
                <div className="error">
                  {t(formik.errors.initialDuration)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                name="valorisation"
                type="number"
                min="0"
                label={t('contract.valorisation')}
                value={formik.values.valorisation}
                onChange={formik.handleChange}
              />
              {formik.errors.valorisation && formik.touched.valorisation ? (
                <div className="error">
                  {t(formik.errors.valorisation)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              {' '}
            </div>
            <div className="form_group">
              <Checkbox
                id="reconduction"
                name="reconduction"
                label={t('contract.reconduction')}
                value={formik.values.reconduction === '1' ? '1' : '0'}
                checked={formik.values.reconduction === '1'}
                onChange={(e) => formik.setFieldValue('reconduction', e.target.checked ? '1' : '0')}
                onBlur={formik.handleBlur}
              />
              {formik.errors.reconduction && formik.touched.reconduction ? (
                <div className="error">
                  {t(formik.errors.reconduction)}
                </div>
              ) : null }
            </div>
            <br />
            {formik.values.reconduction === '1' && (
              <>
                <div className="form_group">
                  <Input
                    name="tacitRenewalYears"
                    type="number"
                    label={t('contract.anneerenouvellement')}
                    value={formik.values.tacitRenewalYears}
                    onChange={formik.handleChange}
                    required={formik.values.reconduction === '1'}
                  />
                  {formik.errors.tacitRenewalYears
                    && formik.touched.tacitRenewalYears
                    && formik.touched.reconduction === 1 ? (
                      <div className="error">
                        {t(formik.errors.tacitRenewalYears)}
                      </div>
                    ) : null }
                </div>
                <div className="form_group">
                  <Select
                    name="noticePeriodDuration"
                    type="select"
                    label={t('contract.dureepreavis')}
                    value={
                      noticePeriodDuration.find(
                        (item) => item.value === formik.values.noticePeriodDuration,
                      )
                     }
                    onChange={(value) => formik.setFieldValue('noticePeriodDuration', value.value)}
                    options={noticePeriodDuration}
                    required={formik.values.reconduction === '1'}
                    valueInput={formik.values.noticePeriodDuration}
                    placeholder={formik.values.noticePeriodDuration}
                  />
                  {formik.errors.noticePeriodDuration
                   && formik.touched.noticePeriodDuration && formik.touched.reconduction === 1 ? (
                     <div className="error">
                       {t(formik.errors.noticePeriodDuration)}
                     </div>
                    ) : null }
                </div>
                <div className="form_group">
                  {' '}
                </div>
              </>
            )}
            <div className="form_group">
              <div className="label">{t('add_training.document_file')}</div>
              <div className="from_group--inline">
                <UploadFile
                  id="documentContract"
                  name="documentContract"
                  label={t('contract.report')}
                  placeholder={getContractQuery?.data?.data?.documentFileName}
                  fileName={formik.values.documentContract ? formik.values.documentContract.name : getContractQuery?.data?.data?.documentFileName}
                  onChange={(event) => formik.setFieldValue('documentContract', event.currentTarget.files[0])}
                  onBlur={formik.handleBlur}
                  setFieldValue={formik.setFieldValue}
                />
              </div>
              {formik.errors.documentContract && formik.touched.documentContract ? (
                <div className="error">
                  {t(formik.errors.documentContract)}
                </div>
              ) : null }
            </div>
          </div>
        ) : null }
        <br />
        <section className="form_footer">
          <div className="form_infos">
            <small>{t('add_structure.mandatory_fields')}</small>
          </div>
          {Permissions({ permission: 'AMENDMENT_EDIT' }) !== undefined
            ? (
              <Button
                type="submit"
                className="form_submit"
                label={t('contract.save')}
              />
            ) : null }
        </section>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('contract.back_listAmendement')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default EditAmendment;
